import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Looper } from 'components/Looper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { Rhythm } from '../index';
import { RhythmBox } from './helpers/RhythmBox';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Rhythm" title="Rhythm" mdxType="PageTitle" />
    <h2 {...{
      "id": "padding"
    }}>{`Padding`}</h2>
    <Playground __position={1} __code={'<Looper\n  end={12}\n  render={i => (\n    <Rhythm key={i} p={i}>\n      <RhythmBox inline>{i}</RhythmBox>\n    </Rhythm>\n  )}\n  start={0}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      Rhythm,
      RhythmBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Looper end={12} render={i => <Rhythm key={i} p={i} mdxType="Rhythm">
        <RhythmBox inline mdxType="RhythmBox">{i}</RhythmBox>
      </Rhythm>} start={0} mdxType="Looper" />
    </Playground>
    <h2 {...{
      "id": "padding-grouped"
    }}>{`Padding grouped`}</h2>
    <Playground __position={2} __code={'<Rhythm grouped p={4} style={{ background: \'#556270\' }}>\n  <Looper\n    end={12}\n    render={i => <RhythmBox inline>{i}</RhythmBox>}\n    start={0}\n  />\n</Rhythm>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      Rhythm,
      RhythmBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Rhythm grouped p={4} style={{
        background: '#556270'
      }} mdxType="Rhythm">
    <Looper end={12} render={i => <RhythmBox inline mdxType="RhythmBox">{i}</RhythmBox>} start={0} mdxType="Looper" />
  </Rhythm>
    </Playground>
    <h2 {...{
      "id": "margins"
    }}>{`Margins`}</h2>
    <Playground __position={3} __code={'<Looper\n  end={12}\n  render={i => (\n    <Rhythm key={i} m={i}>\n      <RhythmBox inline height={20} width={20}>\n        {i}\n      </RhythmBox>\n    </Rhythm>\n  )}\n  start={0}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      Rhythm,
      RhythmBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Looper end={12} render={i => <Rhythm key={i} m={i} mdxType="Rhythm">
        <RhythmBox inline height={20} width={20} mdxType="RhythmBox">
          {i}
        </RhythmBox>
      </Rhythm>} start={0} mdxType="Looper" />
    </Playground>
    <h2 {...{
      "id": "margin-grouped"
    }}>{`Margin grouped`}</h2>
    <Playground __position={4} __code={'<Rhythm grouped m={4} style={{ background: \'#556270\' }}>\n  <Looper\n    end={12}\n    render={i => <RhythmBox inline>{i}</RhythmBox>}\n    start={0}\n  />\n</Rhythm>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      Rhythm,
      RhythmBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Rhythm grouped m={4} style={{
        background: '#556270'
      }} mdxType="Rhythm">
    <Looper end={12} render={i => <RhythmBox inline mdxType="RhythmBox">{i}</RhythmBox>} start={0} mdxType="Looper" />
  </Rhythm>
    </Playground>
    <h2 {...{
      "id": "individual-margins"
    }}>{`Individual margins`}</h2>
    <Playground __position={5} __code={'<Looper\n  end={12}\n  render={i => (\n    <Rhythm key={i} mb={i} ml={i} mr={i} mt={i}>\n      <RhythmBox inline height={20} width={20}>\n        {i}\n      </RhythmBox>\n    </Rhythm>\n  )}\n  start={0}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      Rhythm,
      RhythmBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Looper end={12} render={i => <Rhythm key={i} mb={i} ml={i} mr={i} mt={i} mdxType="Rhythm">
        <RhythmBox inline height={20} width={20} mdxType="RhythmBox">
          {i}
        </RhythmBox>
      </Rhythm>} start={0} mdxType="Looper" />
    </Playground>
    <h2 {...{
      "id": "individual-negative-margins"
    }}>{`Individual negative margins`}</h2>
    <Playground __position={6} __code={'<Looper\n  end={0}\n  render={i => (\n    <div key={i} style={{ margin: \'32px\' }}>\n      <Rhythm mb={i} ml={i} mr={i} mt={i}>\n        <RhythmBox height={40} width={40}>\n          {i}\n        </RhythmBox>\n      </Rhythm>\n    </div>\n  )}\n  start={-4}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      Rhythm,
      RhythmBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Looper end={0} render={i => <div key={i} style={{
        margin: '32px'
      }}>
        <Rhythm mb={i} ml={i} mr={i} mt={i} mdxType="Rhythm">
          <RhythmBox height={40} width={40} mdxType="RhythmBox">
            {i}
          </RhythmBox>
        </Rhythm>
      </div>} start={-4} mdxType="Looper" />
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Rhythm} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      